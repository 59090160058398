:root {
  --bs-primary-rgb: 26, 97, 173;
  --bs-primary: #1c62ad;
  --bs-navbar-active-color: white;
  --bs-link-color: var(--bs-primary);
  --bs-link-color-rgb: 26, 97, 173;
  --bs-link-hover-color: #15508c;
}

#root {
  height: 100vh;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1c62ad;
  --bs-btn-border-color: #1c62ad;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #15508c;
  --bs-btn-hover-border-color: #15508c;
  --bs-btn-focus-shadow-rgb: 2, 40, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #15508c;
  --bs-btn-active-border-color: #15508c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1c62ad;
  --bs-btn-disabled-border-color: #1c62ad;
}

.list-group {
  --bs-list-group-active-bg: #1c62ad;
  --bs-list-group-active-border-color: #1c62ad
}

.nav-pills {
  --bs-nav-pills-link-active-bg: var(--bs-primary)
}

@media (min-width: 992px) {
  .mx-lg-n {
    margin: 0 -3rem;
  }
}
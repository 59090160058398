.logo {
  height: 4em;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link:hover{
  text-decoration: underline;
}

@media (min-width: 576px) {
  .logo {
    height: 5em;
  }

  .navbar-nav .nav-link.active, .navbar-nav .nav-link:hover{
    border-bottom: solid;
    border-bottom-color: currentColor;
    text-decoration: none;
  }

}
.carousel img {
  aspect-ratio: 21/9;
}

div:has(> .twitter-tweet) {
  display: flex;
  justify-content: center;
}

#carouselContainer {
  margin: 0 -1rem;
}